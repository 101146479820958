/*******
 * Layout
 **/

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  background: #e1e1e1;
  text-align: center;
}

.viewport {
  margin: 0 60px;
  position: relative;
  max-width: 1440px;
  //width: 100%;
  width: calc(100% - 120px);
  display: inline-block;

  & > * {
    text-align: left;
  }

  main & {
    width: 100%;
    max-width: 1090px;
  }
}

header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: block;
  height: 84px;
  overflow: hidden;
  background: #fff;

  .logo {
    position: absolute;
    top: 0px;
    left: 0;
    height: 84px;
    width: 85px;
    font-size: 84px;
    line-height: 100%;
    text-decoration: none;
    color: $grey;
  }

  .logo-vinyl {
    position: absolute;
    top: 0px;
    left: 0;
    height: 84px;
    width: 150px;
    font-size: 84px;
    line-height: 105%;
    text-decoration: none;
    color: $grey;
    svg path {
      fill: black;
    }
  }

  nav {
    ul {
      margin: 0;
      padding: 0;
      float: right;
      list-style: none;

      li {
        margin: 0;
        padding: 0;
        float: left;
        a {
          display: block;
          color: $lightGrey;
          text-decoration: none;
          padding: 30px 0;
          margin: 0 20px;
          text-transform: uppercase;
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          position: relative;
          transition: color 0.2s;

          &:after {
            content: "";
            height: 4px;
            background: transparent;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            transition: background 0.2s;
          }

          &.active,
          &:hover {
            color: $grey;

            &:after {
              background: $grey;
            }
          }
        }
      }
    }
  }
}

main {
  position: absolute;
  top: 84px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;

  .fullscreen & {
    top: 0;
  }

  .head {
    background: #1a1b27;
    height: 252px;
    margin-bottom: -120px;
    position: relative;

    h1 {
      i {
        font-size: 22px;
        margin-left: 8px;
        cursor: pointer;

        &:before {
          color: $mediumGrey;
          transition: color 0.2s;
        }

        &:hover {
          &:before {
            color: #fff;
          }
        }
      }

      .playlist-cover {
        width: 80px;
        height: 80px;
        background: #ddd;
        border: 1px solid #fff;
        float: left;
        margin-right: 20px;
        transition: opacity 0.25s;
        cursor: pointer;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .viewport {
      padding-top: 60px;
    }
  }
}

// Small padding
.ps {
  padding: 4px 0;
}

.breadcrumb {
  position: absolute;
  top: 20px;
  left: 0;
  color: $mediumGrey;
  text-decoration: none;

  i {
    font-size: 18px;
    position: relative;
    top: 2px;
  }
}

.tabs {
  position: absolute;
  right: 0;
  top: 62px;

  .tab {
    margin-left: 4px;
    padding: 4px 14px;
    background: transparent;
    border-radius: 40px;
    color: #535466;
    text-decoration: none;
    transition: background 0.25s, color 0.25s;
    display: inline-block;

    &:hover,
    &.active {
      color: #fff;
      background: #2f3042;
    }
  }
}

.filters {
  margin-bottom: 12px;
  float: left;

  & > div {
    float: left;
    margin-right: 12px;
    position: relative;

    &:before {
      position: absolute;
      top: 6px;
      left: 10px;
      font-size: 16px;
    }

    &.select {
      & > div {
        height: 29px;

        & > .css-yk16xz-control,
        & > .css-1pahdxg-control {
          min-height: 29px;
          border-radius: 42px;

          .css-g1d714-ValueContainer {
            width: 145px;
          }

          .css-tlfecz-indicatorContainer,
          .css-1gtu0rj-indicatorContainer {
            padding: 4px;
          }
        }
      }
    }
  }
}

.buttons {
  margin-bottom: 12px;
  float: right;
  & > * {
    float: left;
    margin-left: 8px;
  }
}

.fields {
  .field {
    form & {
      width: 25%;
    }
    form.campaign-form & {
      width: auto;
    }
    float: left;
    padding: 5px 16px 15px 0;
    label {
      min-height: 14px;
    }
    input,
    select {
      width: 100%;
      height: 30px;
    }
  }
}

.block,
.table-wrapper {
  background: #ffffff;
  border: 1px solid #e2e4e9;
  box-shadow: 0px 4px 16px rgba(26, 27, 39, 0.12);
  border-radius: 4px;
}

.block {
  padding: 32px;
  position: relative;

  .loader {
    position: absolute;
  }

  form & {
    padding-bottom: 17px;
  }

  &.above {
    z-index: 10;
  }

  h2 {
    margin-bottom: 14px;
  }
}

.wrapper {
  margin-top: 40px;
  margin-bottom: 80px;

  h2 {
    margin-bottom: 15px;
  }
}

.scheduler-wrapper {
  margin-top: 20px;
  padding: 30px !important;
  overflow: hidden;

  .rbc-header {
    padding-top: 3px;
  }

  .rbc-calendar {
    height: 750px !important;
  }

  .rbc-timeslot-group {
    min-height: 30px;
  }

  .styles-module_day-column__30McI .styles-module_title__2VBFp {
    font-size: 14px;
  }

  .rbc-allday-cell {
    display: none;
  }
  .rbc-time-view .rbc-header {
    border-bottom: none;
  }
}

.login {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background-image: url(images/login.jpg);
  background-size: cover;
  background-position: center;
  background-color: #21202f;

  svg path {
    fill: white;
  }

  .block {
    min-height: 360px;
  }

  // &:before {
  // 	content: '';
  // 	position: absolute;
  // 	top: 0;
  // 	left: 0;
  // 	right: 0;
  // 	bottom: 0;
  // 	background: linear-gradient(152.29deg, #775CC4 12.99%, #4BA2F1 86.83%);
  // 	opacity: 0.6;
  // }

  .logo {
    width: 220px;
    height: 105px;
    color: #fff;
    text-decoration: none;

    &:before {
      font-size: 140px;
    }
  }

  .form-wrapper {
    max-width: 453px;
    width: 100%;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-60%, -50%);

    .block {
      padding: 48px 36px;
    }

    h2 {
      margin-bottom: 40px;
    }

    .field {
      width: 100%;
      text-align: left;
      padding-right: 0;
    }

    form {
      button {
        margin-top: 20px;
        width: 50%;
        font-size: 16px;
      }
    }
  }
  .signin {
    display: none;
  }
}

.device-info {
  &:after {
    clear: both;
    content: "";
    display: block;
  }

  .cell {
    float: left;
    width: 20%;
    padding: 20px 0;
    text-align: center;

    i {
      display: block;
      font-size: 20px;

      &:before {
        color: $mediumGreyText;
      }

      &.icon-pin {
        transform: rotate(180deg);
      }
    }

    span {
      display: block;
      color: $mediumGreyText;
      padding: 2px 0;
    }
  }
}

.rodal {
  outline: none;

  .header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 44px;
    background-color: #ededed;
  }

  .rodal-close {
    top: 14px;
    right: 14px;
    outline: none;
  }

  .rodal-dialog {
    padding: 44px 0 0;
    overflow: hidden;
  }

  .popup-inner {
    padding: 24px;
    h2 {
      margin-bottom: 10px;
    }
    .actions {
      border-top: 0;
    }
  }

  .left {
    width: 510px;
    border-right: 1px solid #dadada;
  }

  .columns {
    height: 510px;
  }

  .columns-mini{
    height: 468px;
  }

  .right {
    width: 280px;

    h2 {
      margin-bottom: 24px;
    }
  }

  .left,
  .right {
    height: 100%;
    padding: 24px;
    float: left;
  }

  .tabs {
    position: relative;
    top: 0;
    margin-bottom: 24px;

    .tab {
      padding: 8px 12px;
      font-weight: 500;
      border-radius: 4px;
      cursor: pointer;
      color: #81838a;
      margin-left: 0;
      margin-right: 5px;

      &.active,
      &:hover {
        color: #0077d6;
        background: #d3eafd;
      }
    }
  }

  .rc-time-picker {
    width: 45%;
    margin-right: 5%;
  }

  h4 {
    text-transform: uppercase;
  }

  .field {
    margin-top: 12px;
    margin-bottom: 24px;
    &.full {
      .rc-time-picker {
        width: 80%;
      }
    }
  }

  .radio {
    padding: 0;

    .partial {
      display: inline-block;
      margin-left: 7px;
      background: #ededed;
      padding: 4px 12px;
      border-radius: 4px;
      margin-top: -4px;
    }

    input[type="text"] {
      padding: 2px 3px;
      width: 50px !important;
      text-align: center;
    }
  }

  .checkbox {
    display: block;
    margin-bottom: 4px;
    label {
      cursor: pointer;
      font-size: inherit;
      color: inherit;
      display: inline-block;
    }
  }

  .actions {
    border-top: 1px solid #dadada;
    text-align: center;
    padding: 23px;

    button {
      margin: 0 5px;
    }
  }
}

.search.select {
  width: 180px;
}

.two-columns-small {
  display: flex;
  .two-columns-unit {
    display: flex;
    align-items: center;
    input {
      width: 50px;
      height: 32px;
      border-radius: 2px;
    }
    span {
      padding: 0 10px;
      font-family: "Roboto";
      font-size: 16px;
      font-weight: 400;
      color: #040715;
    }
  }
}

.audio-file-display {
  display: flex;
  align-items: center;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  .name {
    color: #1e183a;
  }
  .duration {
    margin-left: 8px;
    color: rgba(30, 24, 58, 0.5);
  }
  .play-button {
    cursor: pointer;
    margin-left: 6px;
  }
  .delete-button {
    color: #bcbac4;
    cursor: pointer;
    margin-left: 50px;
  }
}

.flex-end-block {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  .block-unit {
    display: flex;
    margin-left: 24px;
    &:first-of-type {
      margin-left: 0;
    }
  }
}

.event-wrapper {
  min-height: 38px;
  padding: 8px;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  position: absolute;
  overflow: hidden;
  &.playlist,&.collection {
    color: #1d4887;
    border-radius: 6px;
    border: 1px solid #fff;
    background: #e1edff;
  }
  &.voiceOver {
    color: #fff;
    border-radius: 6px;
    background: #fb7e7e;
    border: 1px solid #fff;
    z-index: 1;
    right: 0;
    max-width: 100px;
  }
  .line {
    margin-top: 3px;
    &:first-of-type {
      margin-top: 0;
    }
  }
}

.rbc-events-container {
  margin-right: 0 !important;
}

.rhap_container {
  z-index: 100 !important;
}
